import styled from 'styled-components'
import { ButtonBlack, Container, MainText, PrimaryTitle } from 'styles/Global'

export const DigiactContainer = styled(Container)`
  margin-top: calc(95px + 3rem);
  margin-bottom: 3rem;
  min-height: calc(100vh - (95px + 42px));
`

export const DigiactTitle = styled(PrimaryTitle)`
  margin-bottom: 3rem;
`

export const DigiactText = styled(MainText)`
  margin-bottom: 1rem;
  a {
    color: ${({ theme }) => theme.Colors.red};
    text-decoration: underline;
    transition: 0.3s;
    &:hover {
      opacity: 0.6;
    }
  }
`

export const DigiactButton = styled(ButtonBlack)`
  margin-top: 1.5rem;
`
